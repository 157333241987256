
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IUser } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
})
export default class Profile extends Vue {
  get loading() {
    return !this.user?.id
  }

  get user(): IUser {
    return this.$store.state.profile?.user
  }
}
